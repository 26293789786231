export enum ExportType {
  orderAll = "order_export_all",
  orderPayment = "order_export_by_payment",
  disbursement = "disbursement",
  glifeSubscription = "glife_subscription",
  merchant = "merchant",
  inquiryRequest = "inquiry_request",
  merchantDetails = "merchant_details",
  merchantProducts = "merchant_products",
  merchantAutomations = "merchant_automations",
  billingExport = "billing_export",
  genai = "genai_export"
}

export enum ExportPaymentType {
  gcash = "GCash",
  maya = "Maya",
  bpi = "BPI",
  paymongo = "paymongo",
  billease = "BillEase",
  ninjavan = "CODbyNinjaVan",
}
